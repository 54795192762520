import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';

const Chat = () => {
  const [matchId, setMatchId] = useState('');
  const [userId, setUserId] = useState('');
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [houses, setHouses] = useState([]);

  // Fetch token from local storage
  const token = localStorage.getItem('authToken');

  // Fetch matchId and userId on component mount
  useEffect(() => {
    const fetchMatchAndUser = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/chats/getMatchAndUser', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setMatchId(data.matchId);
          setUserId(data.userId);
        } else {
          console.error('Failed to fetch match and user:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching matchId and userId:', error);
      }
    };
    fetchMatchAndUser();
  }, [token]);

  // Fetch messages and houses
  useEffect(() => {
    if (matchId && userId) {
      const fetchMessages = async () => {
        try {
          const response = await fetch(`http://localhost:5000/api/chats/${matchId}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.ok) {
            const data = await response.json();
            if (Array.isArray(data)) {
              setMessages(data);
            } else {
              console.error('Unexpected data format:', data);
              setMessages([]);
            }
          } else {
            console.error('Failed to fetch messages:', response.statusText);
            setMessages([]);
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
          setMessages([]);
        }
      };

      const fetchHouses = async () => {
        try {
          const response = await axios.get('http://localhost:5000/api/houses/search', {
            params: {
              city: 'Blacksburg, VA', // Hard-coded city
              priceRange: '1800' // Hard-coded price range
            }
          });
          if (response.status === 200) {
            setHouses(response.data);
          } else {
            console.error('Failed to fetch houses:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching houses:', error);
        }
      };

      fetchMessages();
      fetchHouses();
    }
  }, [matchId, userId]);

  // Initialize Socket.IO connection
  useEffect(() => {
    if (matchId && userId) {
      const socketInstance = io('http://localhost:5000');

      socketInstance.on('connect', () => {
        socketInstance.emit('joinRoom', { matchId, userId });
      });

      socketInstance.on('message', (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      });

      setSocket(socketInstance);

      // Cleanup on component unmount
      return () => {
        socketInstance.disconnect();
      };
    }
  }, [matchId, userId]);

  // Send a message
  const handleMessageSend = () => {
    if (messageInput.trim() && socket) {
      socket.emit('chatMessage', { matchId, message: messageInput, userId });
      setMessageInput(''); // Clear input after sending
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 2 }}>
        <h2>Chat</h2>
        {messages.length === 0 ? (
          <p>No messages found.</p>
        ) : (
          <div>
            {messages.map((msg, index) => (
              <div key={index}>
                <strong>{msg.sender}: </strong>{msg.message} <em>{new Date(msg.timestamp).toLocaleString()}</em>
              </div>
            ))}
          </div>
        )}
        <input
          type="text"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
        />
        <button onClick={handleMessageSend}>Send</button>
      </div>
      <div style={{ flex: 1, marginLeft: '20px' }}>
        <h2>Available Houses</h2>
        <p>Based on the hard-coded preferences, here are some houses in Blacksburg, VA with a price range up to 1800:</p>
        {houses.length === 0 ? (
          <p>No houses found.</p>
        ) : (
          <div>
            {houses.map((house) => (
              <div key={house._id}>
                <h3>{house.apartment_name}</h3>
                <p>Price: {house.price}</p>
                <p>Beds: {house.beds}, Baths: {house.baths}</p>
                <p>Address: {house.address}</p>
                <a href={house.source_link} target="_blank" rel="noopener noreferrer">More Info</a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
