import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Survey.css";

const Survey = () => {
  const [budget, setBudget] = useState(0);
  const [age, setAge] = useState("");
  const [name, setName] = useState("");
  const [housePreference, setHousePreference] = useState("");
  const [occupation, setOccupation] = useState("");
  const [city, setCity] = useState("");
  const [moveInDate, setMoveInDate] = useState("");
  const [state, setState] = useState("");
  const [bio, setBio] = useState("");
  const [isSmokingOk, setIsSmokingOk] = useState("");
  const [arePetsOk, setArePetsOk] = useState("");
  const [sleepTime, setSleepTime] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("authToken");
        const response = await axios.get("http://localhost:5000/api/auth/profile", {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUserName(response.data.name);
        // Set other fields if necessary
        setAge(response.data.age || "");
        setBudget(response.data.budget || 0);
        setHousePreference(response.data.housePreference || "");
        setOccupation(response.data.occupation || "");
        setCity(response.data.city || "");
        setMoveInDate(response.data.moveInDate || "");
        setState(response.data.state || "");
        setBio(response.data.bio || "");
        setIsSmokingOk(response.data.isSmokingOk || "");
        setArePetsOk(response.data.arePetsOk || "");
        setSleepTime(response.data.sleepTime || "");
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setError("Failed to fetch user profile. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      budget,
      age,
      name,
      housePreference,
      occupation,
      city,
      moveInDate,
      state,
      bio,
      isSmokingOk,
      arePetsOk,
      sleepTime
    };
    try {
      setLoading(true);
      const token = localStorage.getItem("authToken");
      await axios.put("http://localhost:5000/api/auth/profile", formData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSuccessMessage("Profile updated successfully!");
      setError(""); // Clear any previous errors
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile. Please try again.");
      setSuccessMessage(""); // Clear any previous success messages
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="main-container">
      <div className="rectangle">
        <div className="user-info">
          <h2>Welcome, {userName}!</h2> {/* Display logged-in user's name */}
        </div>
        {loading && <p>Loading...</p>}
        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        {/* Form fields */}
        <div className="flex-row-a">
          <span className="full-name">Full Name<br /></span>
          <span className="house-type-preference">House Type Preference</span>
          <span className="occupation">Occupation?</span>
        </div>
        <div className="flex-row-e">
          <input
            className="survey-name-input"
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="rectangle-2"
            type="text"
            name="house-preference"
            placeholder="House Preference"
            value={housePreference}
            onChange={(e) => setHousePreference(e.target.value)}
          />
          <input
            className="rectangle-3"
            type="text"
            name="occupation"
            placeholder="Occupation"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          />
        </div>
        <div className="flex-row-a">
          <span className="desired-city">Desired City<br /></span>
          <span className="move-in-date">Move In Date</span>
          <span className="upload-profile-picture">Upload Profile Picture</span>
        </div>
        <div className="flex-row-f">
          <input
            className="rectangle-4"
            type="text"
            name="city"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <input
            className="rectangle-5"
            type="date"
            name="movein-date"
            value={moveInDate}
            onChange={(e) => setMoveInDate(e.target.value)}
          />
          <input className="upload" type="file" name="profile-pic" />
        </div>
        <div className="flex-row-a">
          <span className="desired-state">Desired State</span>
          <span className="what-time-sleep">What time do you go to sleep?</span>
          <span className="bio">Bio</span>
        </div>
        <div className="flex-row-f">
          <input
            className="rectangle-4"
            type="text"
            name="state"
            placeholder="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <input
            className="rectangle-5"
            type="time"
            name="sleep-time"
            value={sleepTime}
            onChange={(e) => setSleepTime(e.target.value)}
          />
          <input
            className="rectangle-3"
            type="text"
            name="bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
        </div>
        <div className="flex-row-a">
          <span className="budget">Budget</span>
          <span className="is-smoking-ok">Is Smoking Ok?</span>
          <span className="bio-9">Age</span>
        </div>
        <div className="flex-row-f">
          <div className="rectangle-4">
            <input
              className="budget-slider"
              type="range"
              name="budget"
              min="0"
              max="10000"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
            <label htmlFor="budget">Budget: ${budget}</label>
          </div>
          <div className="rectangle-5">
            <label htmlFor="yes-option">Yes</label>
            <input
              type="radio"
              id="yes-option"
              name="yes-no-question-for-smoke"
              value="Yes"
              checked={isSmokingOk === "Yes"}
              onChange={() => setIsSmokingOk("Yes")}
            />
            <label htmlFor="no-option">No</label>
            <input
              type="radio"
              id="no-option"
              name="yes-no-question-for-smoke"
              value="No"
              checked={isSmokingOk === "No"}
              onChange={() => setIsSmokingOk("No")}
            />
          </div>
          <div>
            <select
              className="rectangle-3"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            >
              <option value="">Select your age</option>
              {Array.from({ length: 83 }, (_, i) => 18 + i).map((age) => (
                <option key={age} value={age}>
                  {age}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex-row-ebd">
          <span className="are-pets-ok">Are Pets Ok?</span>
        </div>
        <div className="flex-row-f">
          <div className="rectangle-4">
            <label htmlFor="yes-option">Yes</label>
            <input
              type="radio"
              id="yes-option"
              name="yes-no-question-for-pets"
              value="Yes"
              checked={arePetsOk === "Yes"}
              onChange={() => setArePetsOk("Yes")}
            />
            <label htmlFor="no-option">No</label>
            <input
              type="radio"
              id="no-option"
              name="yes-no-question-for-pets"
              value="No"
              checked={arePetsOk === "No"}
              onChange={() => setArePetsOk("No")}
            />
          </div>
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          <span className="submit-text">{loading ? "Submitting..." : "Submit Questionnaire"}</span>
        </button>
      </div>
      <div className="rectangle-d"></div>
    </form>
  );
};

export default Survey;
