import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:5000/api/auth/login", formData);
      const { token } = response.data;
      localStorage.setItem('authToken', token); // Store token
      navigate("/survey"); // Redirect to the chat page or other desired page
    } catch (err) {
      console.error(err.response.data);
    }
  };

  const redirectToRegister = () => {
    navigate("/register");
  };

  return (
    <div className="login-container">
      <div className="parent-container">
        <div className="back-shadow-rect">
          <div className="login-rect">
            <span className="login-header">Login</span>
            <input
              className="email-input"
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              className="password-input"
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <button className="login-button" onClick={handleSubmit}>
              <span className="login-text">Login</span>
            </button>
            <button className="register-button" onClick={redirectToRegister}>
              <span className="register">Register</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
