import "./App.css"

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Survey from './pages/Survey';
import Navbar from './components/Navbar';
import RoommateFinder from './pages/RoommateFinder';
import Chat from './pages/Chat'; // Import Chat component

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<RoommateFinder />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/survey" element={<Survey />}/>
        <Route path="/chat" element={<Chat />} /> {/* Add Chat route */}
      </Routes>
    </Router>
  );
}

export default App;
