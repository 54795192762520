// src/components/SwipeableCard.js
import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { useGesture } from "@use-gesture/react";
import "../styles/SwipeableCard.css";

const SwipeableCard = ({ user, onSwipe, onCardLeftScreen }) => {
  const threshold = 100; // Adjust this value as needed

  const [{ x, y, rot, scale }, api] = useSpring(
    () => ({
      x: 0,
      y: 0,
      rot: 0,
      scale: 1,
      config: { friction: 50, tension: 800 },
    })
  );

  const bind = useGesture(
    {
      onDrag: ({
        active,
        movement: [mx, my],
        direction: [dx],
        down
      }) => {
        if (!down && Math.abs(mx) > threshold) {
          // User has released the card beyond the threshold
          const dir = mx > 0 ? "right" : "left";
          api.start({
            x: mx > 0 ? 800 : -800,
            rot: mx > 0 ? 75 : -75,
            scale: 1,
            immediate: false,
            onRest: () => {
                // Animation completed
                onCardLeftScreen(user.id, dir)
            }
          });
          onSwipe(dir, user);
        } else if (down) {
          // While dragging
          api.start({
            x: mx,
            y: my,
            rot: mx / 100,
            scale: 1.1,
            immediate: false,
          });
        } else {
          // Reset position if below threshold
          api.start({
            x: 0,
            y: 0,
            rot: 0,
            scale: 1,
            immediate: false,
          });
        }
      },
    },
    { drag: { filterTaps: true, threshold: 10 } }
  );

  return (
    <animated.div
      className="swipeable-card"
      {...bind()}
      style={{
        x,
        y,
        rotateZ: rot,
        scale,
        touchAction: "none",
      }}
    >
      <div className="card" style={{ backgroundImage: `url(${user.image})` }}>
        <div className="card-info">
          <h3>
            {user.name}, {user.age}
          </h3>
          <p>{user.bio}</p>
        </div>
      </div>
    </animated.div>
  );
};

export default SwipeableCard;
