// src/data/userData.js
export const userData = [
  {
    id: 1,
    name: "Alice Smith",
    age: 25,
    image: "/alicesmith.jpg",
    bio: "Enjoys hiking and outdoor adventures.",
  },
  {
    id: 2,
    name: "Bob Johnson",
    age: 27,
    image: "https://render.fineartamerica.com/images/rendered/default/print/6/8/break/images/artworkimages/medium/3/handsome-squidward-theodore-mitchell.jpg",
    bio: "Tech enthusiast and foodie.",
  },
  {
    id: 3,
    name: "Dwayne Johnson",
    age: 52,
    image: "/DwayneJohnson.jpeg",
    bio: "The Rock, American actor, professional wrestler, and businessman.",
  },
  {
    id: 4,
    name: "Jennifer Aniston",
    age: 55,
    image: "/JenniferAniston.jpg",
    bio: "American actress who achieved stardom on the popular television sitcom Friends.",
  },
  // Add more profiles as needed
];
