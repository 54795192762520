// src/pages/RoommateFinder.js
import React, { useState, useEffect } from "react";
import SwipeableCard from "../components/SwipeableCard";
import { userData } from "../data/userData";
import "../styles/RoommateFinder.css";

const RoommateFinder = () => {
  const [users, setUsers] = useState([]);
  const [lastDirection, setLastDirection] = useState(null);

  useEffect(() => {
    // Load user data
    setUsers(userData);
  }, []);

  const swiped = (direction, user) => {
    console.log(`You swiped ${direction} on ${user.name}`);
    setLastDirection(direction);

    // Handle accept or refuse actions
    if (direction === "right") {
      // Accept roommate logic
      acceptRoommate(user.id);
    } else if (direction === "left") {
      // Refuse roommate logic
      refuseRoommate(user.id);
    }
  };

  const cardLeftScreen = (userId, direction) => {
    console.log(`Card for user ID ${userId} has left the screen to the ${direction}`);
    // Remove the swiped user from the list
    setUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId));
  };

  const acceptRoommate = (userId) => {
    // Implement accept roommate logic here
    // For example, send a POST request to your API
    console.log(`Accepted roommate with ID: ${userId}`);
  };

  const refuseRoommate = (userId) => {
    // Implement refuse roommate logic here
    console.log(`Refused roommate with ID: ${userId}`);
  };

  return (
    <div className="roommate-finder">
      <div className="card-container">
        {users.length > 0 ? (
          users.map((user) => (
            <SwipeableCard 
                key={user.id} 
                user={user} 
                onSwipe={swiped}
                onCardLeftScreen={cardLeftScreen}
            />
          ))
        ) : (
          <h2>No more profiles</h2>
        )}
      </div>
      {lastDirection && (
        <h2 className="infoText">You swiped {lastDirection}</h2>
      )}
    </div>
  );
};

export default RoommateFinder;
