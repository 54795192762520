import React, { useState } from "react";
import axios from "axios";
import "../styles/Register.css";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:5000/api/auth/register", formData);
      console.log("Registration successful:", response.data);
      setSuccessMessage("Registration successful!"); // Set success message
      setErrorMessage(""); // Clear error message
      setFormData({ name: "", email: "", password: "" }); // Clear the form
    } catch (error) {
      console.error("Error registering user:", error.response?.data?.msg || error.message);
      setSuccessMessage(""); // Clear success message on error
      setErrorMessage(error.response?.data?.msg || "An error occurred. Please try again."); // Set error message
    }
  };

  return (
    <div className="main-container">
      <div className="block-holder">
        <div className="flex-column-bf">
          <span className="testimonial-0">
            “Found me my roommate for next year”
            <br />
            - User, Isabella Goff
          </span>
          <span className="testimonial-1">
            “I used this service to find an affordable housing option in DC”
            <br />
            - User, Volodymyr Larin
          </span>
          <span class="testimonial-2">
            “I found my best friend using cabinIt”
            <br />
            - User, Brandon Aybar
          </span>
        </div>
        <div className="group">
          <div className="register-back-shadow-rect">
            <div className="register-foreground-rect">
              <span className="register-header">Register</span>
              <form onSubmit={handleSubmit}>
                <input
                  className="register-email-input"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <input
                  className="register-username-input"
                  type="text"
                  name="name"
                  placeholder="Username"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <input
                  className="register-password-input"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <button className="register-register-button" type="submit">
                  <span className="login">Register</span>
                </button>
              </form>
              {successMessage && <p className="success-message">{successMessage}</p>}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
